import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ClaimService } from '../services/claim.service';
import { ClientService } from '../services/client.service';
import Claim from '../models/Claim';
import { ClaimListPaginationConfig } from '../models/ClaimListPaginationConfig';
import { ClaimListResponse } from '../models/ClaimListResponse';
import { SearchCriteria } from '../models/SearchCriteria';
import SearchClaimPrepay from '../models/SearchClaimPrepay';
import { GuardianService } from '../services/abstracts/guardian.service';

@Injectable({
  providedIn: 'root'
})

export class ClaimStore {
  initialResponse: ClaimListResponse = {
    result: {
      data: {
        claimResponse: {
          pageNumber: 0,
          paginationSize: 0,
          totalPages: 0,
          totalRecordSize: 0,
          claims: []
        }
      }
    }
  };

  private claimsSubject: BehaviorSubject<ClaimListResponse> = new BehaviorSubject(this.initialResponse);
  public readonly claims$: Observable<ClaimListResponse> = this.claimsSubject.asObservable();
  private exportClaimsSubjectPrepay: BehaviorSubject<ClaimListResponse> = new BehaviorSubject(this.initialResponse);
  public readonly exportClaims$: Observable<ClaimListResponse> = this.exportClaimsSubjectPrepay.asObservable();
  private readonly searchClaimsSubjectPrepay: BehaviorSubject<ClaimListResponse> = new BehaviorSubject(this.initialResponse);
  public readonly searchClaimsPrepay$: Observable<ClaimListResponse> = this.searchClaimsSubjectPrepay.asObservable();
  defaultSearchCriteria: SearchCriteria;
  defaultSearchRequestData: SearchClaimPrepay;
  claimMap: Map<string, Claim> = new Map<string, Claim>();

  constructor(
    private readonly router: Router,
    private readonly claimService: ClaimService,
    private readonly clientService: ClientService,
    private readonly guardianService: GuardianService) {
    this.defaultSearchCriteria = ClaimListPaginationConfig.defaultSearchCriteria;
    this.defaultSearchRequestData = ClaimListPaginationConfig.defaultSearchRequestData;
  }

  private unauthorized(searchCriteria: SearchCriteria): void {
    if ((!searchCriteria.clientName && !this.clientService.getCurrentClient())
      || (searchCriteria.tins && searchCriteria.tins.length === 0 && !this.guardianService.getTinValues())) {
      this.router.navigate(['unauthorized']);
    }

  }

  async queryClaims(searchCriteria: SearchCriteria): Promise<void> {
    this.claimsSubject.next(this.initialResponse);
    this.unauthorized(searchCriteria);
    if (!searchCriteria.clientName && this.clientService.getCurrentClient()) {
      // eslint-disable-next-line no-param-reassign
      searchCriteria = {
        ...searchCriteria,
        clientName: this.clientService.getCurrentClient().clientName,
      };
    }
    if (searchCriteria.tins.length === 0 && this.guardianService.getTinValues()) {
      // eslint-disable-next-line no-param-reassign
      searchCriteria = {
        ...searchCriteria,
        tins: (await this.guardianService.getTinValues())?.tin     
      };
    }
    if (searchCriteria.clientName && searchCriteria.tins && searchCriteria.tins.length > 0) {
      this.claimService.queryAllClaims(searchCriteria, res => {
        let i: number;
        for (i = 0; i < (res.result.data.claimResponse.claims.length); i++) {
          this.claimMap.set(res.result.data.claimResponse.claims[i].patientAccountNumber,
            res.result.data.claimResponse.claims[i]);
        }
        this.claimsSubject.next(res);
      }, error => {
        this.claimsSubject.error(error);
      });
    }
  }

  queryClaimsByAccountNo(patientAccountNumber: string): Claim {
    return this.claimMap.get(patientAccountNumber);
  }

  async exportClaimsPrepay(searchCriteria: SearchCriteria): Promise<void> {
    this.exportClaimsSubjectPrepay.next(this.initialResponse);
    if (!searchCriteria.clientName && this.clientService.getCurrentClient()) {
      // eslint-disable-next-line no-param-reassign
      searchCriteria = {
        ...searchCriteria,
        clientName: this.clientService.getCurrentClient().clientName,
      };
    }
    if (searchCriteria.tins.length === 0 && this.guardianService.getTinValues()) {
      // eslint-disable-next-line no-param-reassign
      searchCriteria = {
        ...searchCriteria,
        tins: (await this.guardianService.getTinValues())?.tin 
      };
    }
    if (searchCriteria.clientName && searchCriteria.tins && searchCriteria.tins.length > 0) {
      this.claimService.exportAllClaimsPrepay(searchCriteria, res => {
          this.exportClaimsSubjectPrepay.next(res);
        },
        error => {
          this.exportClaimsSubjectPrepay.error(error);
        }
      );
    }
  }

  searchClaimsPrepay(searchClaimRequestBody: SearchClaimPrepay): void {
    this.searchClaimsSubjectPrepay.next(this.initialResponse);
    if (!searchClaimRequestBody.clientName && this.clientService.getCurrentClient()) {
      // eslint-disable-next-line no-param-reassign
      searchClaimRequestBody = {
        ...searchClaimRequestBody,
        clientName: this.clientService.getCurrentClient().clientName,
      };
    }
    if (searchClaimRequestBody.clientName && searchClaimRequestBody.searchValue && searchClaimRequestBody.searchKey) {
      this.claimService.searchClaimsPrepay(searchClaimRequestBody, res => {
          let i: number;
          for (i = 0; i < (res.result.data.claimResponse.claims.length); i++) {
            this.claimMap.set(res.result.data.claimResponse.claims[i].patientAccountNumber,
              res.result.data.claimResponse.claims[i]);
          }
          this.searchClaimsSubjectPrepay.next(res);
        },
        error => {
          this.searchClaimsSubjectPrepay.error(error);
        }
      );
    }
  }
}