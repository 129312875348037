import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { EntitlementService } from './abstracts/entitlement.service';
import Environment from '../models/Environment';
import ProviderClientData from '../models/ProviderClientData';
import { environment } from '../../../environments/environment';
import { LettersInventoryService } from "./letter-inventory.service"
import { GlobalConstants } from '../constants/GlobalConstants';
import { GuardianService } from './abstracts/guardian.service';

@Injectable({
  providedIn: 'root'
})
export class CpEntitlementService implements EntitlementService {
  sessionStorage: Storage;
  clientUrl: string;
  env: Environment;
  tinData: string[];
  functionKey: string;
  label: string;
  getCpAPiResponseKeyData: string;
  jwtTokenData: string;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly guardianService: GuardianService,
    private readonly lettersInventoryService: LettersInventoryService,
  ) {
    this.clientUrl = environment.guardian.optumGuardianUrl;
    this.env = environment;
    this.sessionStorage = window.sessionStorage;
    this.tinData = [];
  }

  async getClientsByTinValues(next: (res: ProviderClientData) => void, error: (error: Error) => void) {
    this.tinData = (await this.guardianService.getTinValues())?.tin || [];
    this.lettersInventoryService.getJwtToken((token) => {
      this.jwtTokenData = token;
      this.httpClient.post<ProviderClientData>(`${this.env.lettersInventory.baseUrl}${this.env.lettersInventory.endpointGetClients}`,
        {
          "tins": this.tinData,
          "letterType": GlobalConstants.LETTER_TYPE_MRR
        }, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })
        .pipe(
          map(clientData => {
            clientData.result.data.clientList.sort((clientA, clientB) => clientA.clientName > clientB.clientName ? 1 : -1);
            return clientData;
          }),
          catchError((error) => {
            return throwError(error)
          })
        ).subscribe({
          next,
          error
        })
    }, (err) => {
      throw (err)
    })
  }

  getToken(): string {
    return this.jwtTokenData;
  }
}