import { Component, ChangeDetectorRef, OnInit, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { HttpEvent, HttpEventType } from '@angular/common/http';
import { AuthService } from '../shared/services/abstracts/auth.service';
import { GlobalConstants } from '../shared/constants/GlobalConstants';
import Claim from '../shared/models/Claim';
import { DocumentInfoService } from '../shared/services/document-info.service';
import { SearchCriteriaDocumentsInfo } from '../shared/models/SearchCriteriaDocumentsInfo';
import DocumentInfo from '../shared/models/DocumentInfo';
import { DocumentInfoConfig } from '../shared/models/DocumentInfoConfig';
import { ClientService } from '../shared/services/client.service';
import FileDownloadRequestData from '../shared/models/FileDownloadRequestData';
import { CpMrUploadService } from '../shared/services/cp-mr-upload.service';
import SearchCriteriaUploadID from '../shared/models/SearchCriteriaUploadID';
import { DocumentDownloadService } from '../shared/services/document-download.service';
import { base64toBlob, combineChunks, downloadBlob, formatBytes } from '../shared/helpers/FileDownloadHelper';
import FileDownloadResponse from '../shared/models/FileDownloadResponse';

@Component({
    selector: 'app-document-info',
    templateUrl: './document-info.component.html',
    styleUrls: ['./document-info.component.scss']
})

export class DocumentInfoComponent implements OnInit {
  isCloseButtonEnabled: boolean;
  patientAccountNumberText: string;
  claimNumberText: string;
  isDocInfoDialogVisible: boolean = false;
  isLoggedIn: boolean;
  selectedClaim: Claim;
  subscription: Subscription;
  tableHeader: Array<Object>;
  tableDetail: DocumentInfo[];
  searchCriteriaDocumentInfo: SearchCriteriaDocumentsInfo;
  patientName: string;
  documentsText: string;
  documentDetailsText: string;
  isCloseEnabled: boolean;
  sessionStorage: Storage;
  transferStatusKey: string;
  dataTransferringStatus: string;
  dataTranferredStatus: string;
  onDownloadClick: boolean;
  isFileDownloaded: boolean;
  isErrorStatus: boolean;
  isFileDownloading: boolean;
  fileSize: number | string;
  closeDownloadBox: boolean;
  errorMessage: string;
  errorBoxVisible: boolean;
  selectedFileName: string;
  downloadingString: string;
  downloadedString: string;
  closeButtonText: string;
  sizeText: string;
  spinnerSize: string;
  documentLoadingMessage: string;
  isLoading: boolean;
  hasDocuments: boolean;
  NoDocMessage: string;
  downloadInProgressMessage: string;
  isFileLarge: boolean;
  largeFileDownloadMessage: string;

  constructor (
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly documentInfoService: DocumentInfoService,
    private readonly authService: AuthService,
    private readonly cpMrUploadService: CpMrUploadService,
    private readonly clientService: ClientService,
    private readonly documentDownloadService: DocumentDownloadService
  ) {
    this.isCloseButtonEnabled = true;
    this.patientAccountNumberText = GlobalConstants.PATIENT_AC_NUMBER_TEXT;
    this.documentsText = GlobalConstants.DOCUMENTS_TEXT;
    this.patientName = GlobalConstants.PATIENT_AC_NAME_TEXT;
    this.claimNumberText = GlobalConstants.CLAIM_NUMBER_TEXT;
    this.isDocInfoDialogVisible = false;
    (async () => { this.isLoggedIn = await this.authService.isAuthenticated(); }) ();
    this.searchCriteriaDocumentInfo = DocumentInfoConfig.defaultSearchCriteriaDocumentInfo;
    this.tableHeader = DocumentInfoConfig.tableHeader;
    this.documentDetailsText = GlobalConstants.DOCUMENT_DETAILS_TEXT; // not displaying.. check
    this.isCloseEnabled = false;
    this.sessionStorage = window.sessionStorage;
    this.transferStatusKey = GlobalConstants.TRANSFER_STATUS_FOR_IDLE_CHECK_KEY;
    this.dataTransferringStatus = GlobalConstants.DATA_TRANSFERING_STATUS_FOR_IDLE_CHECK;
    this.dataTranferredStatus = GlobalConstants.DATA_TRANSFERRED_STATUS_FOR_IDLE_CHECK;
    this.onDownloadClick = false;
    this.isFileDownloaded = false;
    this.isErrorStatus = false;
    this.isFileDownloading = false;
    this.fileSize = GlobalConstants.FILE_SIZE_PLACEHOLDER;
    this.closeDownloadBox = true;
    this.errorMessage = GlobalConstants.DOWNLOAD_ERROR_MESSAGE;
    this.errorBoxVisible = false;
    this.selectedFileName = '';
    this.downloadingString = GlobalConstants.DOWNLOADING_STRING;
    this.downloadedString = GlobalConstants.DOWNLOADED_STRING;
    this.closeButtonText = GlobalConstants.CLOSE_BUTTON_TEXT;
    this.sizeText = GlobalConstants.SIZE_WITH_COLON_TEXT;
    this.spinnerSize = GlobalConstants.SPINNER_SIZE_SMALL;
    this.documentLoadingMessage = GlobalConstants.DOWNLOADING_LOADING_MESSAGE;
    this.isLoading = true;
    this.hasDocuments = false;
    this.NoDocMessage = GlobalConstants.NO_DOC_AVAILABLE;
    this.downloadInProgressMessage = GlobalConstants.DOWNLOADING_MESSAGE;
    this.tableDetail = [];
    this.isFileLarge = false;
    this.largeFileDownloadMessage = GlobalConstants.LARGE_FILE_DOWNLOAD_MSG;
  }

  ngOnInit(): void {
    this.changeDetectorRef.detectChanges();
    this.isLoading = true;
    this.subscription = this.documentInfoService.documentInfoAction$.subscribe(claim => {
      this.isLoading = true;
      this.isDocInfoDialogVisible = true;
      this.selectedClaim = claim;
      const searchCriteriaUploadID: SearchCriteriaUploadID = {
        claimID: claim.claimNumber,
        claimType: GlobalConstants.PREPAY_TEXT,
        clientName: this.clientService.getCurrentClient()?.clientName
      };
      this.cpMrUploadService.getMrUploadStatus(searchCriteriaUploadID).then(res => res.subscribe(uploadIDFromDB => {
        this.isLoading = true;
        const searchCriteriaDocumentInfo: SearchCriteriaDocumentsInfo = { 
          clientName: this.clientService.getCurrentClient().clientName?.toString()?.toUpperCase(),
          claimId: this.selectedClaim.claimNumber,
          uploadId: uploadIDFromDB
        }; 
        this.changeDetectorRef.detectChanges();
        this.documentInfoService.queryGetClaimDetails(searchCriteriaDocumentInfo).then(data => data.subscribe(response => {
          this.tableDetail = response.claimDetailsResult;
          this.hasDocuments = (this.tableDetail && (this.tableDetail.length > 0));
          this.isLoading = false;
          this.changeDetectorRef.detectChanges();
        }));
      }));
    });
  }

  /**
  * Closes the document information dialog and performs cleanup.
  */
  closeDialogInfo(): void {
    this.isDocInfoDialogVisible = false;
    this.changeDetectorRef.detectChanges();
    this.tableDetail = [];
    this.closeDownloadBox = true;
    this.errorBoxVisible = false;
    this.selectedFileName = '';
  }

  /**
  * Initiates the asynchronous download of a file.
  *
  * @param item The document information of the file to be downloaded.
  */
  async downloadFile(item: DocumentInfo): Promise<void> {
    this.changeDetectorRef.detectChanges();
    this.isFileLarge = false;
    if (this.errorBoxVisible) { 
      this.errorBoxVisible = false; 
    }
    if (this.isFileDownloaded) { 
      this.isFileDownloaded = false;
      this.isFileDownloading = true;
    }
    this.changeDetectorRef.detectChanges();
    this.selectedFileName = item.documentName;
    try {
      this.onDownloadClick = true;
      this.closeDownloadBox = false;
      this.isErrorStatus = false;
      const requestData: FileDownloadRequestData = {
        clientName: this.clientService.getCurrentClient()?.clientName?.toUpperCase(),
        downloadFileData: item.downloadFileData
      };
      
      this.documentDownloadService.downloadFile(requestData).then(res => res.subscribe(
        (downloadResult: HttpEvent<FileDownloadResponse>) => {
          const intervalId = setInterval(() => {
            this.isFileLarge = true;
          }, 20000);
          if (downloadResult.type === HttpEventType.DownloadProgress) {
            this.isFileDownloaded = false;
            this.isFileDownloading = true;
            clearInterval(intervalId);
            this.isFileLarge = true;
            this.changeDetectorRef.detectChanges();
          }
          if (downloadResult.type === HttpEventType.Response) {
            const combinedFileData = combineChunks(downloadResult.body.fileContents);
            const blob: Blob = base64toBlob(combinedFileData);
            this.fileSize = formatBytes(blob.size);
            downloadBlob(blob, item.documentName);
            this.isFileDownloaded = true;
            this.isFileDownloading = false;
            this.isFileLarge = false;
            clearInterval(intervalId);
            this.sessionStorage.setItem(this.transferStatusKey, JSON.stringify(this.dataTranferredStatus));
            this.changeDetectorRef.detectChanges();
          }
        }, (error: any) => {
          console.error('Error downloading file:', error);
          this.isErrorStatus = true;
          this.isFileLarge = false;
          this.showErrorBox();
          this.changeDetectorRef.detectChanges();
        }
      ));
    } catch (error) {
      console.error('Error:', error);
      this.isFileLarge = false;
      this.changeDetectorRef.detectChanges();
      throw error;
    }
  }
    
  /**
  * Displays an error box and resets related properties after a timeout.
  */
  private showErrorBox(): void {
    this.errorBoxVisible = true;
    this.isFileLarge = false;
    this.changeDetectorRef.detectChanges();
  }
}