/* eslint-disable no-shadow */
import { catchError, map } from 'rxjs/operators';
// eslint-disable-next-line import/no-unresolved
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { ClientService } from './client.service';
import { GuardianService } from './abstracts/guardian.service';
import Claim from '../models/Claim';
import { ClaimListResponse } from '../models/ClaimListResponse';
import { SearchCriteria } from '../models/SearchCriteria';
import { formatDateString } from '../helpers/DateHelper';
import { environment } from '../../../environments/environment';
import { GlobalConstants } from '../constants/GlobalConstants';
import SearchClaimPrepay from '../models/SearchClaimPrepay';
import { LettersInventoryService } from './letter-inventory.service';
import Environment from '../models/Environment';
import LetterVerificationResponse from '../models/LetterVerificationResponse';
import LetterForm from '../models/LetterForm';
import ManualUploadForm from '../models/ManualUploadForm';

@Injectable({
  providedIn: 'root'
})

export class ClaimService {
  doesPrepayClaimExists: boolean;
  env: Environment;
  jwtToken: string;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly clientService: ClientService,
    private readonly guardianService: GuardianService,
    private readonly lettersInventoryService: LettersInventoryService,
  ) {
    this.doesPrepayClaimExists = false;
    this.env = environment;
    this.jwtToken = '';
  }

  async queryAllClaims(searchCriteria: SearchCriteria, next: (res: ClaimListResponse) => void, error: (error: Error) => void) {
    const { baseUrl, endpointGetClaims } = this.env.lettersInventory;
    // eslint-disable-next-line no-param-reassign
    searchCriteria = {
      ...searchCriteria,
      clientName: !searchCriteria.clientName
        ? this.clientService.getCurrentClient().clientName : searchCriteria.clientName,
      tins: (searchCriteria.tins && searchCriteria.tins.length === 0)
        ? (await this.guardianService.getTinValues())?.tin  : searchCriteria.tins
    };

    this.lettersInventoryService.getJwtToken((token) => {
      this.jwtToken = token;
      this.httpClient.post<ClaimListResponse>(`${baseUrl}${endpointGetClaims}`, searchCriteria, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })
        .pipe(
          map(res => {
            this.sortMedicalRecordRequestDate(res);
            return res;
          }),
          map(res => {
            this.existClaims(res);
            return res;

          }),
          map(res => {
            const { claimResponse } = res.result.data;
            // Validate Claims Fields
            const validatedClaims: Claim[] = this.validateClaimsEmptyFields(res.result.data.claimResponse.claims);
            const formattedClaims: Claim[] = validatedClaims.map(claim => ({
              ...claim,
              medicalRecordRequestDate: formatDateString(claim.medicalRecordRequestDate),
              patientDoB: formatDateString(claim.patientDoB),
              startDateOfService: formatDateString(claim.startDateOfService),
              throughDateOfService: formatDateString(claim.throughDateOfService)
            }));
            return {
              result: {
                data: {
                  claimResponse: {
                    ...claimResponse,
                    claims: formattedClaims,
                  }
                }
              }
            };
          }),
          catchError((error) => throwError(error))
        ).subscribe({
          next,
          error
        });
    }, (err) => { throw (err); });
  }

  exportAllClaimsPrepay(searchCriteria: SearchCriteria, next: (res: ClaimListResponse) => void, error: (error: Error) => void) {
    const { baseUrl, endpointGetClaims } = this.env.lettersInventory;
    // eslint-disable-next-line no-param-reassign
    this.setSearchCriteriaForExport(searchCriteria).then(res => searchCriteria = res);

    this.lettersInventoryService.getJwtToken((token) => {
      this.jwtToken = token;
      this.httpClient.post<ClaimListResponse>(`${baseUrl}${endpointGetClaims}`, searchCriteria, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })
        .pipe(
          map(res => {
            res.result.data.claimResponse.claims.sort(
              (a, b) => new Date(a.medicalRecordRequestDate).getTime() > new Date(b.medicalRecordRequestDate).getTime() ? 1 : -1
            );
            return res;
          }),
          map(res => {
            this.existClaims(res);
            return res;
          }),
          map(res => {
            const { claimResponse } = res.result.data;
            // Validate Claims Fields
            const validatedClaims: Claim[] = this.validateClaimsEmptyFields(res.result.data.claimResponse.claims);
            const formattedClaims: Claim[] = validatedClaims.map(claim => ({
              ...claim,
              medicalRecordRequestDate: formatDateString(claim.medicalRecordRequestDate),
              patientDoB: formatDateString(claim.patientDoB),
              startDateOfService: formatDateString(claim.startDateOfService),
              throughDateOfService: formatDateString(claim.throughDateOfService)
            }));
            return {
              result: {
                data: {
                  claimResponse: {
                    ...claimResponse,
                    claims: formattedClaims,
                  }
                }
              }
            };
          }),
          catchError((error) => throwError(error))
        ).subscribe({
          next,
          error
        });
    }, (err) => { throw (err); });
  }

  searchClaimsPrepay(searchClaimRequestBody: SearchClaimPrepay, next: (res: ClaimListResponse) => void, error: (error: Error) => void) {
    const { baseUrl, endpointSearchClaims } = this.env.lettersInventory;
    // eslint-disable-next-line no-param-reassign

    this.lettersInventoryService.getJwtToken((token) => {
      this.jwtToken = token;
      this.httpClient.post<ClaimListResponse>(`${baseUrl}${endpointSearchClaims}`, searchClaimRequestBody, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })
        .pipe(
          map(res => {
            this.sortMedicalRecordRequestDate(res);
            return res;
          }),
          map(res => {
            this.existClaims(res);
            return res;
          }),
          map(res => {
            const { claimResponse } = res.result.data;
            // Validate Claims Fields
            const validatedClaims: Claim[] = this.validateClaimsEmptyFields(res.result.data.claimResponse.claims);
            const formattedClaims: Claim[] = validatedClaims.map(claim => ({
              ...claim,
              medicalRecordRequestDate: formatDateString(claim.medicalRecordRequestDate),
              patientDoB: formatDateString(claim.patientDoB),
              startDateOfService: formatDateString(claim.startDateOfService),
              throughDateOfService: formatDateString(claim.throughDateOfService)
            }));
            return {
              result: {
                data: {
                  claimResponse: {
                    ...claimResponse,
                    claims: formattedClaims,
                  }
                }
              }
            };
          }),
          catchError((error) => throwError(error))
        ).subscribe({
          next,
          error
        });
    }, (err) => { throw (err); });
  }

  verifyLetter(letterForm: LetterForm, next: (res: LetterVerificationResponse) => void, error: (error: Error) => void){
    const { baseUrl, endpointVerificationClaims } = this.env.lettersInventory;
    this.lettersInventoryService.getJwtToken((token) => {
      this.jwtToken = token;
      return this.httpClient.post<LetterVerificationResponse>(`${baseUrl}${endpointVerificationClaims}`, letterForm, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })
        .pipe(
          map(res => {
            return res;
          }),
          catchError((error) => {
            return error;
          })
        ).subscribe({
          next,
          error
        });
    }, (err) => { throw (err)});
    return null;
  }
  
  getToken(): string {
    return this.jwtToken;
  }

  uploadAndVerifyManualRecord(manualForm: ManualUploadForm, next: (res: LetterVerificationResponse) => void, error: (error: Error) => void) {
    const { baseUrl, endpointManualUpload } = this.env.lettersInventory;

    const newFormData = new FormData();
    newFormData.append('authorizationCode', manualForm.authorizationCode);
    newFormData.append('memberLetterBarCode', manualForm.memberLetterBarCode);
    newFormData.append('startDateOfService', manualForm.startDateOfService);
    newFormData.append('userEmail', manualForm.userEmail);
    newFormData.append('file', manualForm.file, manualForm.file.name);
    console.log(newFormData);
    this.lettersInventoryService.getJwtToken((token) => {
      this.jwtToken = token;
      return this.httpClient.post(`${baseUrl}${endpointManualUpload}`, newFormData, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })
        .pipe(
          map(res => {
            console.log(JSON.stringify(res));
            return res;
          }),
          catchError((error) => error)
        ).subscribe({
          next,
          error
        });
    }, (err) => { throw (err);});
    return null;
  }

  private async setSearchCriteriaForExport(searchCriteria: SearchCriteria): Promise<SearchCriteria> {
    try {
      const updatedSearchCriteria: SearchCriteria = { ...searchCriteria };
      if (!updatedSearchCriteria.clientName) {
        updatedSearchCriteria.clientName = this.clientService.getCurrentClient().clientName;
      }
      if (!updatedSearchCriteria.tins || updatedSearchCriteria.tins.length === 0) {
        const tinValues = await this.guardianService.getTinValues();
        updatedSearchCriteria.tins = tinValues?.tin || [];
      }
      updatedSearchCriteria.pageNumber = GlobalConstants.EXPORT_DEFAULT_PAGE_NO;
      updatedSearchCriteria.paginationSize = GlobalConstants.EXPORT_DEFAULT_PAGINATION_Size;
      return updatedSearchCriteria;
    } catch (error) {
      console.error('Error setting search criteria for export:', error);
      throw error; // Rethrow the error or handle it as per your application's needs
    }
  }

  private sortMedicalRecordRequestDate(res): void {
    res.result.data.claimResponse.claims?.sort(
      (a, b) => new Date(a.medicalRecordRequestDate).getTime() > new Date(b.medicalRecordRequestDate).getTime() ? 1 : -1
    );
  }

  private existClaims(res) {
    if (res && res.result && res.result.data && res.result.data.claimResponse && res.result.data.claimResponse.claims && res.result.data.claimResponse.claims.length > 0) {
      this.doesPrepayClaimExists = true;
    } else {
      this.doesPrepayClaimExists = false;
    }
  }

  private validateClaimsEmptyFields(claims: Claim[]) {
    return claims.map(claim => {
      for (const k in claim) {
        const dateFields = ["medicalRecordRequestDate", "patientDoB", "startDateOfService", "throughDateOfService"];
        if (dateFields.includes(k)) {
          const checkDate = Date.parse(claim[k]);
          // If empty or null then use default value2
          if (isNaN(checkDate)) {
            claim[k] = '1900-01-01';
          } 
        }
        const value = claim[k];
        if (value === null || value === undefined) {
          claim[k] = " ";
        }
      }
      return claim;
    });
  }
}