import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UITKLoadingIndicatorModule, UITKNotificationModule, UITKPageNotificationModule, UITKDialogModule } from '@uitk/angular';
import { DocumentInfoComponent } from './document-info.component';
import { FilesModule } from '../files/files.module';
import { HttpClientModule} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [DocumentInfoComponent],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    UITKLoadingIndicatorModule,
    UITKNotificationModule,
    UITKPageNotificationModule,
    UITKDialogModule,
    FilesModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule
  ],
  exports: [DocumentInfoComponent]
})
export class DocumentInfoModule { }