import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppConfigService } from './app-config.service';
import { environment } from '../../../environments/environment';
import { GlobalConstants } from '../constants/GlobalConstants';
import { LettersInventoryService } from './letter-inventory.service';
import Environment from '../models/Environment';
import LogMedicalRecordData from '../models/LogMedicalRecordData';
import UploadData from '../models/UploadData';
import SearchCriteriaUploadID from '../models/SearchCriteriaUploadID';

@Injectable({
  providedIn: 'root'
})
export class CpMrUploadService {
  mrUploadUrl: string;
  logMrUploadFunctionKey: string;
  logMrUpload_label: string;
  env: Environment;
  getMrUploadFunctionKey: string;
  getMrUpload_label: string;

  getMrUploadUrl: string;

  constructor(private readonly httpClient: HttpClient,
    private readonly appConfigService: AppConfigService,
    private readonly lettersInventoryService: LettersInventoryService,) {
    this.mrUploadUrl = environment.mrUpload.mrUploadUrl;
    this.logMrUploadFunctionKey = GlobalConstants.LOG_MRUPLOAD_FUNCTIONS_KEY;
    this.logMrUpload_label = GlobalConstants.LOG_MRUPLOAD_FUNCTIONS_KEY_LABEL;
    this.getMrUploadFunctionKey = GlobalConstants.GET_MRUPLOAD_FUNCTIONS_KEY;
    this.getMrUpload_label = GlobalConstants.GET_MRUPLOAD_FUNCTIONS_KEY_LABEL;
    this.env = environment;
  }

  /**
   * This method is to call LettersInvetory API as well as the
   * old implementation in order to track users
   * 
   * @param LogMedicalRecordData 
   * @returns Promise<Observable<string>>
   */
  async logMrUploadStatus(mrUploadDataToLog: LogMedicalRecordData): Promise<Observable<string>> {
    // Call LettersInvetory API
    const { baseUrl, endpointUpdateClaimStatus } = this.env.lettersInventory;
    if (mrUploadDataToLog.mrUploadStatus !== 'F') {
      try {
        await this.lettersInventoryService.getJwtToken((token) => {
          this.httpClient.post<string>(`${baseUrl}${endpointUpdateClaimStatus}`, mrUploadDataToLog, {
            headers: {
              "Authorization": `Bearer ${token}`
            }
          }).subscribe();
        }, (err) => throwError(err));
      } catch (error) {
        throw error;
      }
    }
    // Call intermidiate backend to track a user
    return this.appConfigService.getConfiguration(this.logMrUploadFunctionKey, this.logMrUpload_label).then(res =>
      this.httpClient.post<string>(`${this.mrUploadUrl}/logMrUploadStatus?code=${res}`, mrUploadDataToLog)
        .pipe(
          map(data => data),
          catchError((error) => throwError(error))
        )
    ).catch((error) => throwError(error));
  }

  /**
 * Retrieves the uploadID stored in the DB based on the provided search criteria.
 *
 * @param {SearchCriteriaUploadID} searchCriteriaUploadID - The search criteria for Mr. Upload status.
 * @returns {Promise<Observable<string[]>>} A Promise that resolves to an Observable of string array representing Mr. Upload status.
 * @throws {Error} If there is an error during the process.
 */
  getMrUploadStatus(searchCriteriaUploadID: SearchCriteriaUploadID): Promise<Observable<string[]>> {
    return this.appConfigService.getConfiguration(this.getMrUploadFunctionKey, this.getMrUpload_label).then(res =>
      this.httpClient.post<UploadData>(`${this.mrUploadUrl}/getMrUploadStatus?code=${res}`, searchCriteriaUploadID)
        .pipe(
          map(data => data?.uploadIDs.filter(id => id !== null)),
          catchError((error) => throwError(error))
        )
    ).catch((error) => throwError(error));
  }
}