import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { AppConfigService } from './app-config.service';
import LogPreferenceData from '../models/LogPreferenceData';
import GetPreferenceResponse from '../models/GetPreferenceResponse';
import GetPreferenceData from '../models/GetPreferenceData';
import { GlobalConstants } from '../constants/GlobalConstants';

@Injectable({
  providedIn: 'root'
})
export class ProviderPreferenceService {
  preferenceUrl: string;
  private token: string;
  authUrl: string;
  oauth_key: string;
  oauth_label: string;

  constructor (private readonly httpClient: HttpClient, private readonly appConfigService: AppConfigService
  ) {
    this.preferenceUrl = environment.consentMgmt.preferenceUrl;
    this.authUrl = environment.guardian.optumGuardianUrl;
    this.oauth_key = GlobalConstants.OAUTH_TOKEN_KEY;
    this.oauth_label = GlobalConstants.OAUTH_TOKEN_LABEL;
  }

  public getToken(): string {
    return this.token;
  }

  logPreference(logPreferenceData: LogPreferenceData): Observable<string> {
    const headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8', 
      'Authorization': `Bearer ${this.getToken()}`});
    const requestOptions = { headers };
    return this.httpClient.post<string>(`${this.preferenceUrl}/logConsentMgmntPrefs`, logPreferenceData, requestOptions)
    .pipe(
      map(data => data),
      catchError((error) => throwError(error))
  );
  }

  async getPreference(getPreferenceData: GetPreferenceData, next:(res: GetPreferenceResponse) => void, error: (error:Error) => void): Promise<void> {
    this.getBearerToken().then(response => response.subscribe(token => {
      this.httpClient.post<GetPreferenceResponse>(`${this.preferenceUrl}/getConsentMgmntPrefs`, getPreferenceData, {
      headers: {
        'Content-type': 'application/x-www-form-urlencoded; charset=utf-8', 
        'Authorization': `Bearer ${token}`
      }
      }).pipe(
          map(preferenceData => preferenceData),
          catchError((err) => throwError(err))
        )
        .subscribe({
          next,
          error
        });
    }, (err) => {
      throw(err);
    })).catch(errors => throwError(errors));
  }

  getBearerToken(): Promise<Observable<string>> {
    return this.appConfigService.getConfiguration(this.oauth_key, this.oauth_label).then(res =>
    this.httpClient.get<string>(`${this.authUrl}/getBearerApiResponse?code=${res}`)
      .pipe(
        map(token => {
          this.token = token;
          return token;
        }),
        catchError((error) => throwError(error))
      )
    ).catch(error => throwError(error));
  }
}