import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import { Observable } from 'rxjs';
import { EntitlementService } from '../services/abstracts/entitlement.service';
import { ClaimService } from '../services/claim.service';
import { ProviderPreferenceService } from '../services/provider-preference.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private readonly entitlementService: EntitlementService, 
    private readonly claimService: ClaimService, 
    private readonly providerPreferenceService: ProviderPreferenceService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token: string = '';

    if (this.shouldUseJwtToken(request)) {
      token = this.entitlementService.getToken();

      // Use a more concise check for token existence
      if (!token) {
        token = this.claimService.getToken();
      }

      request = this.addTokenToRequest(request, token);
    } 

    if (this.shouldUseBearerToken(request)) {
      token = this.providerPreferenceService.getToken();
      request = this.addTokenToRequest(request, token);
    }

    return next.handle(request);
  }

  private shouldUseJwtToken(request: HttpRequest<any>): boolean {
    return request.url.includes('/api/claims') || request.url.includes('/api/upload');
  }

  private shouldUseBearerToken(request: HttpRequest<any>): boolean {
    return (
      request.url.includes('/preference') ||
      request.url.includes('/getConsentMgmntPrefs') ||
      request.url.includes('/logConsentMgmntPrefs') ||
      request.url.includes('/SendEmailWithOTP') ||
      request.url.includes('/verifyEmailWithOTP') ||
      request.url.includes('/sendVerificationEmail')
    );
  }

  private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    if (token) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return request;
  }
}