import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import SendEmailData from '../models/SendEmailData';
import VerifyOTPData from '../models/VerifyOTPData';
import { ProviderPreferenceService } from './provider-preference.service';

@Injectable({
  providedIn: 'root'
})
export class SendEmailOtpService {
  emailUrl: string;
  token: string;

  constructor(private readonly httpClient: HttpClient, private readonly providerPreferenceService: ProviderPreferenceService) {
    this.emailUrl = environment.consentMgmt.preferenceUrl;
  }

  async sendEmailWithOtp(sendEmailData: SendEmailData, next: (value: HttpResponse<HttpResponse<string>>) => void, error?: (error: any) => void): Promise<void> {
    this.providerPreferenceService.getBearerToken().then(response => response.subscribe(token => {
      this.token = token;
      this.httpClient.post<HttpResponse<string>>(`${this.emailUrl}/SendEmailWithOTP`, sendEmailData, {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
          'Authorization': `Bearer ${token}`
        },
        observe: 'response',
        reportProgress: true
      }).pipe(
        map(res => res),
        catchError(err => throwError(err))
      )
        .subscribe({ next, error })
    }, (err) => {
      throw (err)
    })).catch(errors => throwError(errors))
  }

  verifyOtp(verifyOtpData: VerifyOTPData): Observable<string> {
    const headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      'Authorization': `Bearer ${this.token}`
    });
    const requestOptions = { headers: headers };
    return this.httpClient.post<string>(`${this.emailUrl}/verifyEmailWithOTP`, verifyOtpData, requestOptions)
      .pipe(
        map(otpVerifiedMessage => otpVerifiedMessage),
        catchError((error) => throwError(error))
      )
  }
}