import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { AppConfigService } from './app-config.service';
import { GlobalConstants } from '../constants/GlobalConstants';
import { JWTService } from './abstracts/jwt.service';

/**
 * This class is used to getting a JWT for accesing a Letters Inventory API
 */
@Injectable({
  providedIn: 'root'
})
export class LettersInventoryService implements JWTService {
  private readonly authUrl = environment.guardian.optumGuardianUrl
  private readonly jwt_token_key: string
  private readonly jwt_token_label: string

  constructor(
    private readonly appConfigService: AppConfigService,
    private readonly http: HttpClient,
  ) {
    this.jwt_token_key = GlobalConstants.GET_LETTERS_INVENTORY_JWT_TOKEN_KEY;
    this.jwt_token_label = GlobalConstants.GET_LETTERS_INVENTORY_JWT_TOKEN_LABEL;
  }

  /**
   * This method is used to get a JWT
   * 
   * @param next is a callback function to handle response in subscription method from Observable
   * @param error is a callback function to handle error in subscription method from Observable
   * 
   * @returns Promise<void>
   */
  async getJwtToken(next: (res: string) => void, error: (err: Error) => void) {
    let jwtTokenSecret: string
    // Get client secret and id configuration
    try {
      jwtTokenSecret = await this.appConfigService.getConfiguration(this.jwt_token_key, this.jwt_token_label)
    } catch (error) {
      throw error
    }

    this.http.get<string>(`${this.authUrl}/getLettersInventoryAccessToken?code=${jwtTokenSecret}`, {
      headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" }
    }).subscribe({
      next,
      error
    })
  }
}