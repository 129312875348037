import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProviderPreferenceService } from './provider-preference.service';
import VerificationEmailData from '../models/VerificationEmailData';

@Injectable({
  providedIn: 'root'
})
export class SendVerificationEmailService {
  emailUrl: string;
  token: string;

  constructor(private readonly httpClient: HttpClient, private readonly bearerTokenService: ProviderPreferenceService) {
    this.emailUrl = environment.consentMgmt.preferenceUrl;
  }

  async sendVerificationEmail(sendEmailData: VerificationEmailData, next: (value: HttpResponse<string>) => void, error?: (error: any) => void): Promise<void> {
    this.bearerTokenService.getBearerToken().then(response => response.subscribe(token => {
      this.token = token;
      this.httpClient.post<string>(`${this.emailUrl}/sendVerificationEmail`, sendEmailData, {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
          'Authorization': `Bearer ${token}`
        },
        observe: 'response'
      }).pipe( map(res => res), catchError(err => throwError(err))).subscribe({ next, error })
    }, (err) => { return (err); })).catch(errors => throwError(errors))
  }
}