import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import FileDownloadRequestData from '../models/FileDownloadRequestData';
import { environment } from '../../../environments/environment';
import { GlobalConstants } from '../constants/GlobalConstants';
import { AppConfigService } from './app-config.service';
import FileDownloadResponse from '../models/FileDownloadResponse';

@Injectable({
  providedIn: 'root'
})
export class DocumentDownloadService {
  downloadFunctionKey: string;
  downloadLabel: string;
  downloadFileEndpoint: string;

  constructor(
    private readonly httpClient: HttpClient, 
    private readonly appConfigService: AppConfigService)
  {
    this.downloadFunctionKey = GlobalConstants.GET_DOWNLOAD_RESPONSE_KEY;
    this.downloadLabel = GlobalConstants.GET_DOWNLOAD_RESPONSE_LABEL;
    this.downloadFileEndpoint = environment.guardian.optumGuardianUrl;
  }

  /**
  * Downloads a file with progress events.
  *
  * @param {FileDownloadRequestData} fileDownloadRequestData - Data for the file download request.
  * @returns {Promise<Observable<HttpEvent<FileDownloadResponse>>>} - Observable emitting download progress and response events.
  * @throws {Error} - Thrown if there's an error during configuration retrieval or HTTP request.
  */
  downloadFile(fileDownloadRequestData: FileDownloadRequestData): Promise<Observable<HttpEvent<FileDownloadResponse>>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.appConfigService.getConfiguration(this.downloadFunctionKey, this.downloadLabel).then(key => 
      this.httpClient.post<FileDownloadResponse>(
        `${this.downloadFileEndpoint}/getODIAPIFileDownloadResponse?code=${key}`,
        fileDownloadRequestData,
        { headers: headers, reportProgress: true, observe: 'events' }
      )
    ).catch(error => throwError(error));
  }
}